
// Imported in non-production environments only, helps with debugging
import 'zone.js/dist/zone-error';

import type { EnvironmentInterface } from '../interfaces/src/interfaces/environment.interface';

export const environment: EnvironmentInterface = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCIB9urP8dH5QCjrIo8c5d6gOyS7bauf4Y",
    authDomain: "blush-dev-425512.firebaseapp.com",
    projectId: "blush-dev-425512",
    storageBucket: "blush-dev-425512.appspot.com",
    messagingSenderId: "785880765805",
    appId: "1:785880765805:web:268822b588bdcfb8ebf664",
    measurementId: "G-B05C5CM472",
    databaseURL:"https://blush-dev-425512-default-rtdb.firebaseio.com"
  },
  apiUrl: 'https://us-central1-blush-dev-425512.cloudfunctions.net',
  mapsApiKey: 'AIzaSyBs4kc0MSe-kxU8w2lrDfhauw2Xfq_yqKc',
  release: '1.3.0',
  envName: 'DEV',
  brandConnectUrl: 'https://connect-dev-blush.web.app',
  searchAPIkey: 'd673dc9651f37064602675b471404a43',
  makioUrl: 'https://makio-prod.leafvip.app',
  baseBrandConnectUrl: 'https://connect.dev.blushco.io',
  baseOfficeConnectUrl: 'https://office.dev.blushco.io',
  baseTeamManagerUrl: 'https://team-manager.dev.blushco.io',
  leafVipBrandId: 'ZQ7AtssmT3NzNnAfGfbB',
};
